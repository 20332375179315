<template>
    <div class="role">
        <Block />
        <DeleteModal v-if="deleted == true" @close="closeModal"/>
        <div class="role__block">
            <div class="role__block__top">
                <div class="role__block__top__title">
                    <p>Роли</p>
                </div>
                <div class="role__block__top__time">
                    <p>20:32</p>
                    <p style="font-size: 12px;">29 сентября</p>
                </div>
            </div>
            <div class="line"></div>
            <div class="role__block__button" v-if="this.$store.state.prophile.role[0] == 'admin'">
                <button @click="openAdd = true">Добавить</button>
            </div>
            <div class="role__block__add" v-if="openAdd == true">
                <div class="role__block__add__name">
                    <input type="text" placeholder="Введите имя" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="role__block__add__role">
                    <select v-model="v$.role.$model">
                        <option value="" disabled selected hidden>Выберите роль</option>
                        <option v-for="(item, idx) in newRoles" :key="idx" :value="item">
                            {{ item.name_ru }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.role.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="role__block__add__login">
                    <input type="email" placeholder="Введите e-mail" v-model="v$.login.$model"/>
                    <template v-for="(error) of v$.login.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="role__block__add__name">
                    <input type="text" placeholder="Введите пароль" v-model="v$.password.$model"/>
                    <template v-for="(error) of v$.password.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="role__block__add__img">
                    <select v-model="v$.branch.$model">
                        <option value="" disabled selected hidden>Выберите Аквапарк</option>
                        <option v-for="(item, idx) in newBranches" :key="idx" :value="item">
                            {{ item.title }}
                        </option>
                    </select>
                    <template v-for="(error) of v$.branch.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="role__block__add__save">
                    <button @click="sendData()">Сохранить</button>
                    <img src="@/assets/icons/exit.svg" @click="openAdd = false"/>
                </div>
            </div>
            <div class="role__block__tabletop">
                <div>
                    <p>Имя</p>
                </div>
                <div>
                    <p>Роль</p>
                </div>
                <div>
                    <p>Логин</p>
                </div>
                <div>
                    <p>Пароль</p>
                </div>
                <div>
                    <p>Аквапарк</p>
                </div>
                <div></div>
            </div>
            <div class="role__block__table">
                <div class="role__block__table__in" v-for="item in newUser" :key="item">
                    <div class="role__block__table__in__top">
                        <div class="role__block__table__in__top__name">
                            <input type="text" :placeholder="item.name" :disabled="editableItemId !== item.id" v-model="item.name"/>
                        </div>
                        <div class="role__block__table__in__top__role">
                            <input type="text" :disabled="editableItemId !== item.id" :v-model="item.role" v-if="editableItemId !== item.id" :placeholder="item.role_ru"/>
                            <select v-model="item.role[0]" v-if="editableItemId == item.id">
                                <option value="" disabled selected hidden>Выберите новую роль</option>
                                <option v-for="(role, idx) in newRoles" :key="idx" :value="item.role[0]">
                                    {{ role.name_ru }}
                                </option>
                            </select>
                        </div>
                        <div class="role__block__table__in__top__login">
                            <input type="text" :placeholder="item.email" :disabled="editableItemId !== item.id" v-model="item.email"/>
                        </div>
                        <div class="role__block__table__in__top__password">
                            <input type="text" placeholder="*********" v-model="item.password" :disabled="editableItemId !== item.id"/>
                        </div>
                        <div class="role__block__table__in__top__role">
                            <input type="text" :disabled="editableItemId !== item.id" :v-model="item?.branch?.title" v-if="editableItemId !== item.id" :placeholder="item?.branch?.title"/>
                            <select v-model="justBranch" v-if="editableItemId == item.id">
                                <option value="" disabled selected hidden>Выберите новый аквапарк</option>
                                <option v-for="(branch, idx) in newBranches" :key="branch" :value="branch.id">
                                    {{ branch.title }}
                                </option>
                            </select>
                        </div>
                        <div class="role__block__table__in__top__yesno">
                            <button v-if="editableItemId == item.id" @click="updateData(item)">Сохранить</button>
                            <img v-if="editableItemId == item.id" src="@/assets/icons/exit.svg" @click="toggleEdit(item.id)"/>
                            <img src="@/assets/icons/edit.svg" @click="toggleEdit(item.id)" v-if="editableItemId !== item.id"/>
                            <p @click="deleteData(item.id)" style="color: rgb(173, 62, 62); cursor: pointer;" v-if="this.$store.state.prophile.role[0] == 'admin' && editableItemId !== item.id">delete</p>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Block from '@/components/Block.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import { useVuelidate } from '@vuelidate/core'
import axios from "axios";
import { required, minLength, email, helpers, numeric, alphaNum } from '@vuelidate/validators'
import { toast } from 'vue3-toastify';
export default {
    setup () {
        return { 
            v$: useVuelidate() 
        }
    },
    components:{
        Block, DeleteModal
    },
    data(){
        return{
            deleted: false,
            editableItemId: null,
            name: '',
            role: '',
            login: '',
            password: '',
            branch: '',
            justBranch: '',
            newRoles: [],
            newUser: [],
            newBranches: [],
            file: '',
            openAdd: false,
        }
    },
    validations() {
    return {
        name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Слишком короткое название', minLength(4)),
            },
          role: {
            required: helpers.withMessage('Обязательное поле', required),
          },
          branch: {
            required: helpers.withMessage('Обязательное поле', required),
          },
          login : {
            email: helpers.withMessage('Неправильный формат', email),
            required: helpers.withMessage('Обязательное поле', required),
          },
          password: {
            required: helpers.withMessage('Обязательное поле', required),
            minLength: helpers.withMessage('Слишком короткое название', minLength(8)),
            alphaNum: helpers.withMessage('Пароль не может содержать символы', alphaNum)
          },
          
        };
    },
    async created() {
        this.getPage()    
    },
    methods:{
        toggleEdit(itemId) {
            this.editableItemId = this.editableItemId === itemId ? null : itemId;
        },
        closeModal(){
            this.deleted = false;
        },
        async getPage() {    
            await this.$axios.get(`roles/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newRoles = res.data
            })
            .catch(err => {
            })    
            await this.$axios.get(`branches/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newBranches = res.data.data
            })
            .catch(err => {
            }) 
            await this.$axios.get(`users/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newUser = res.data.data
            })
            .catch(err => {
            })     
        },
        deleteData(value){
            const backId = {
                id: value
            }
            axios
                .post("https://api-ems.mydev.kz/api/users/delete", backId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.deleted = true
                        window.location.reload(true);
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
        updateData(value){
            console.log(value);
            
            const back = {
                id: value.id,
                name: value.name,
                password: value.password,
                email: value.email,
                role: value.role[0],
                branch_id: this.justBranch
            };
            axios.post("https://api-ems.mydev.kz/api/users/update", back, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        window.location.reload(true);
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        },
        sendData() {
        if (!this.v$.$invalid) {
            let data = new FormData() 
            data.append("name", this.name) 
            data.append("email", this.login) 
            data.append("role", this.role.name) 
            data.append("password", this.password) 
            data.append("branch_id", this.branch.id)
            axios.post("https://api-ems.mydev.kz/api/users/create", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Успешно сохранено!')
                        this.getPage()
                        this.openAdd = false
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
    },
    handleFileChange() { 
        this.file = this.$refs.fileInput.files['0']; 
        console.log(this.file);
    },
}
}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.line{
    border: 1px solid #0000001A;
}
    .role{
        width: 100%;
        display: flex;
        justify-content: end;
        &__block{
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 87%;
            &__add{
                display: flex;
                justify-content: space-between;
                &__save{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        flex-direction: row !important;
                        img{
                            cursor: pointer;
                        }
                    }
                div{
                    width: 16%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    input{
                        border: none;
                        outline: none;
                        border-bottom: 1px solid black;
                        padding: 5px;
                        width: 90%;
                        &::placeholder{
                            text-align: center;
                        }
                    }
                    select{
                        border: none;
                        outline: none;
                        border-bottom: 1px solid black !important;
                        padding: 5px;
                        width: 90%;
                    }
                    button{
                        border: 1px solid #0000001A;
                        padding: 5px 37px;
                        background: inherit;
                        color: #1965EA;
                        border-radius: 10px;
                        cursor: pointer;
                    }
                }
            }
            &__button{
                display: flex;
                justify-content: end;
                button{
                    border: 1px solid #0000001A;
                    padding: 5px 37px;
                    background: inherit;
                    color: #1965EA;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
            &__top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-size: 22px;
                    font-weight: 600;
                }
                &__time{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            &__tabletop{
                display: flex;
                justify-content: space-between;
                border: 1px solid #0000001A;
                padding: 18px 0px;
                font-weight: 600;
                div{
                    width: 20%;
                    display: flex;
                    justify-content: center;
                }
            }
            &__table{
                display: flex;
                flex-direction: column;
                gap: 15px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    &__top{
                        display: flex;
                        justify-content: space-between;
                        color: #5F5F5F;
                        align-items: center;
                        &__yesno{
                            display: flex;
                            gap: 10px;
                        }
                        &__title{
                            color: #1965EA;
                        }
                        button{
                            background: inherit;
                            padding: 5px 20px;
                            border-radius: 10px;
                            border: 1px solid #0000001A;
                            color: #1965EA;
                            cursor: pointer;
                        }
                        select{
                                color: #1965EA;
                                padding: 5px 10px;
                                display: flex;
                                align-items: center;
                                border: 1px solid #0000001A;
                                border-radius: 10px;
                                outline: none;
                            }
                        div{
                            width:20%;
                            display: flex;
                            justify-content: center;
                            input{
                                outline: none;
                                text-align: center;
                                border-radius: 10px;
                                background: inherit;
                            }
                            img{
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
</style>